<template>
  <div class="login page">
    <div class="login-logo"></div>
    <div class="login__input-box">
      <div class="login__input-box-get-password" v-show="showBackPassWork">
        <!--        找回密码-->
        <div class="login__input-box-get-password-title">
          修改密码
        </div>
      </div>
      <div class="login__input-box-login" v-show="!showBackPassWork">
        <div class="login__input-box-login-type">
          <span :class="{'active': loginType === 1}" @click="loginType=1">密码登录</span> <span></span> <span
            :class="{'active': loginType === 2}" @click="loginType=2">短信登录</span>
        </div>
        <div class="login__input-box-content">
          <div class="login__input-box-username" v-show="loginType === 1">
            <!--          账号密码登录-->
            <el-form ref="usernameForm" :model="usernameForm" :rules="rules">
              <el-form-item prop="mobile">
                <el-input v-model="usernameForm.mobile" placeholder="请输入您的账号" clearable @keyup.native.enter="hLogin">
                  <img slot="prefix"
                       src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAABdklEQVQ4T6XUTatNYRjG8d8VyUukjHSOgRKJgQ9AKTIwoZyhYiAlSgwoikRRMlHMDNAZKMUUE5+AzChK3pUzENKh3FraW9tp7RedVWvy3Nfzv+/1XNezouWpqrnYji0Yxw88xd0kT9r2ZOZiVS3AGaxt64HJJLdm1tpAh7G1rWvP2ukkj3o1/4CqagluYM4Q0OMkpwaBNuDsEEhT/pxk9yDQOlwYATSVZO8g0DzcxMIhsIdJLvUFNYWqmsCeAaBpHE3yatBEY0neVtU+7GiBfcNFNDl7keRTV/PXtarahoO4kuR+Va3BZozhZyeQ97AaJ/AVJ5O8bmB/QFW1Hud6bH+AO11RR7MUOztvNx4fcCjJdBd0GStbPuUjptDka3mffF1PcjtVtQJXR7C8n+RNkgMNaBOOzQJU2NWANuL4LEETDWgZro1wv/r1ep7kSPewG5v3Y/F/TvYO55O87M1RY+mqzo9sEea3TNmcx3d8wXs8S/Kraf4bC7V7GIaeriEAAAAASUVORK5CYII="
                       alt="">
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="usernameForm.password" placeholder="请输入您的密码" clearable show-password @keyup.native.enter="hLogin">
                  <img slot="prefix"
                       src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAABXElEQVQ4T6XUPWtVQRSF4WcJFoKIIiJooUEsLAVFU6UQEVt/Q0xhZWFnEEEUFJEQAmlSpBErO0FEJFippZVgo50fIIKFkEK3TLgJ8XguNzfucs6sd69Zs+dET1XVHlzCJA5gFe/xNMnrPk26i1V1BLewDx8HgN04iV14kWSuq/sLVFU7sIhDWEjybF1QVXtxA8cxn+T5ZlgXdAL38DbJbI/bod+7oLO4Psx+VbUjPsKnJDP/OKqqBjyIM5jGGzzsCbVldBffcBNfk/xs+zLocgcTfbcxYu0XlpI8aaApXNsGZF3yOcnlBjqHq/8B+pJkehRopQ0hfuM8LvQ0HAl6laRlt1FV1Zy3E2yukaD7SV52QKcHQzkWaDnJ4w7oIq6M6+gHZpN8aMKqOozb2D8uqO1vc/JuEHZ7Hju3E/ZWJ2Ij7KN4MKTbVmBrt7v2aKvqGE6h/UbGqe9YSbL6B9OmjhPWj2CNAAAAAElFTkSuQmCC"
                       alt="">
                </el-input>
              </el-form-item>
            </el-form>
          </div>

          <div class="login__input-box-phone" v-show="loginType === 2">
            <!--            验证码登录-->
            <el-form ref="codeForm" :model="codeForm" :rules="rules">
              <el-form-item prop="mobile">
                <el-input v-model="codeForm.mobile" placeholder="请输入您的账号" clearable @keyup.native.enter="hLogin">
                  <img slot="prefix"
                       src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAABdklEQVQ4T6XUTatNYRjG8d8VyUukjHSOgRKJgQ9AKTIwoZyhYiAlSgwoikRRMlHMDNAZKMUUE5+AzChK3pUzENKh3FraW9tp7RedVWvy3Nfzv+/1XNezouWpqrnYji0Yxw88xd0kT9r2ZOZiVS3AGaxt64HJJLdm1tpAh7G1rWvP2ukkj3o1/4CqagluYM4Q0OMkpwaBNuDsEEhT/pxk9yDQOlwYATSVZO8g0DzcxMIhsIdJLvUFNYWqmsCeAaBpHE3yatBEY0neVtU+7GiBfcNFNDl7keRTV/PXtarahoO4kuR+Va3BZozhZyeQ97AaJ/AVJ5O8bmB/QFW1Hud6bH+AO11RR7MUOztvNx4fcCjJdBd0GStbPuUjptDka3mffF1PcjtVtQJXR7C8n+RNkgMNaBOOzQJU2NWANuL4LEETDWgZro1wv/r1ep7kSPewG5v3Y/F/TvYO55O87M1RY+mqzo9sEea3TNmcx3d8wXs8S/Kraf4bC7V7GIaeriEAAAAASUVORK5CYII="
                       alt="">
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input v-model="codeForm.code" placeholder="请输入验证码" class="login__input-box-phone-code-input" @keyup.native.enter="hLogin">
                  <img slot="prefix"
                       src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAABZUlEQVQ4T6WUTStFURSGn5ciDAykDDDBD1BK+SiSCULK1A/AxB8w8DF1/we5SZSSoa9SzO8IY8rA9VEsrdvet3N173U71uSczln7We9a+91bhDCzLmAW6AYa4vcqz0/gATiU9ChPNLN2YA1oqgHwO+UNyETQEtCfAhKX3EbQNtD8D1A+gjL/gBSWpgVlgTtgGehIAzJgV9JV2KRVoKcS6MuTgTZgMqoGvoF9SRdm5p0sAMPxf7nWniVthooTwFSYX1bSeYDMA6OJIhVndAYcSzIzGwfeJV2aWV1CScn+REU7SXrIOAVOJHlLbtp6wJWMlNnhQq+etAW0lEkowEKRudBOOae8RtAK0FvBS/eAq+ms4rVcBI0BXjFtHESQn/YNoDEF6QNYL4DCnIaAxRSgPfdWEuTv04B7p9Yo2qQICqrcJ4PAzB+3QR44Aq6jPUpAiTZbgQGgLxwVL+B+egJywI2kl6TsH1uViP3EvYauAAAAAElFTkSuQmCC"
                       alt="">

                  <div slot="suffix" class="login__input-box-phone-code-input-suffix" @click="hGetCode"
                       :class="{'cant': typeof codeTime === 'number'}">
                    {{ codeTime }}<span v-show="typeof codeTime === 'number'">S后获取</span>
                  </div>
                </el-input>
              </el-form-item>
            </el-form>
          </div>

<!--          <div class="login__input-box-back" @click.stop="gShowBackPassWork">-->
<!--            找回密码-->
<!--          </div>-->
        </div>


        <div class="login-input-box-button" @click="hLogin">
          登录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formValidate} from '@/utils/mixins/formValidate'

export default {
  name: "Login",
  mixins: [formValidate],
  data() {
    let checkMobile = (rule, value, callback) => {
      if (value === '' || value === null) {
        return callback(new Error('用户名不能为空'))
      }
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        return callback(new Error('请填写正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      showBackPassWork: false,
      loginType: 1,
      usernameForm: {
        mobile: null,
        password: null,
      },
      codeForm: {
        mobile: null,
        code: null,
      },
      rules: {
        mobile: [
          {validator: checkMobile, trigger: ['change', 'blur']},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ]
      },
      codeTime: '获取验证码',
      codeTimer: null,
    }
  },
  created() {

  },
  mounted() {
    if (localStorage.getItem('login-info')){
      this.$router.replace('/')
    }
  },
  methods: {
    async gLogin() {
      let url, data;
      this.$pageLoadingOpen()
      if (this.loginType === 1) {
        url = '/online/user/user/userLogin'
        data = {
          phone: this.usernameForm.mobile,
          cipherText: this.$jsEncrypt(this.usernameForm.password)
        }
      } else if (this.loginType === 2) {
        url = '/online/user/user/phoneLogin'
        data = {
          phone: this.codeForm.mobile,
          code: this.codeForm.code
        }
      }
      const _res = await this.$_api(url, 'post', data)

      this.$pageLoadingClose()
      if (_res.result) {
        localStorage.setItem('login-info', JSON.stringify(_res))
        await this.$router.push('/');
      }
    },
    hLogin() {
      let formName;
      if (this.loginType === 1) {
        formName = 'usernameForm'
      } else if (this.loginType === 2) {
        formName = 'codeForm'
      }
      this.beforeFormSubmit(formName).then(() => {
        this.gLogin()
      })
    },
    async hGetCode() {
      // 获取验证码
      if (this.codeForm.mobile) {
        await this.$_api('/online/user/user/phoneGetCode', 'get', undefined, {
          phone: this.codeForm.mobile
        })
      } else {
        this.$message.error('请输入手机号')
        return false
      }
      this.codeTime = 60
      this.codeTimer = setInterval(() => {
        this.codeTime--
      }, 1000)
    },
    gShowBackPassWork(){
      this.showBackPassWork = true
    }
  },
  watch: {
    codeTime(v) {
      if (typeof v === 'number' && v === 0) {
        clearInterval(this.codeTimer)
        console.log(this.codeTimer)
        this.codeTime = '重新获取'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  user-select: none;
  position: relative;
  background: url("../assets/image/bg.png") no-repeat;
  background-size: 100% 100%;

  .login-logo {
    position: absolute;
    width: 222px;
    height: 56px;
    background: url("../assets/image/logo_r.png") no-repeat;
    background-size: 100% 100%;
    top: 16px;
    left: 16px;
  }

  .login__input-box {
    width: 580px;
    height: 430.17px;
    position: absolute;
    top: 60px;
    left: 250px;
    background: url("../assets/image/bg_login.png") no-repeat;
    background-size: 100% 100%;

    .login__input-box-get-password-title{
      color: #FFF;
    }

    .login__input-box-login {
      padding: 108px 149px;

      .login__input-box-login-type {
        span {
          color: #FFF;
          opacity: 0.5;
          font-size: 16px;
          line-height: 21px;
          transition: all .4s;
          cursor: pointer;

          &.active {
            font-weight: bold;
            font-size: 20px;
            opacity: 1;
            font-family: Microsoft YaHei;
          }
        }

        & > :nth-child(2) {
          display: inline-block;
          margin: 0 50px;
          width: 2px;
          height: 16px;
          background: #FFF;
          opacity: 1;
        }
      }

      .login__input-box-content {
        margin-top: 32px;

        .el-form {
          /deep/ .el-form-item__error {
            color: #FFF;
          }
        }

        .el-input {
          /deep/ input {
            height: 50px;
            border-radius: 38px;
            background: #DB3723;
            color: #FFF;
            border: none;
          }

          /deep/ .el-input__suffix, /deep/ i {
            color: #FFF;
          }

          /deep/ .el-input__inner {
            padding: 0 45px;
          }

          /deep/ .el-input__prefix {
            padding: 0 11px;

            img {
              margin-top: 16px;
            }
          }
        }

        .login__input-box-phone-code-input {
          /deep/ .el-input__inner {
            padding-right: 110px;
          }

          .login__input-box-phone-code-input-suffix {
            margin-top: 5px;
            width: 98.06px;
            height: 40px;
            background: url("../assets/image/bt_code.png") no-repeat;
            background-size: 100% 100%;
            color: #FFF;
            font-size: 14px;
            cursor: pointer;
            font-family: Microsoft YaHei;

            span {
              color: #FFF;
            }

            &.cant {
              opacity: .6;
            }
          }
        }

      }

      .login__input-box-back {
        text-align: right;
        font-size: 12px;
        color: #FEDCD6;
        padding-right: 12px;
        cursor: pointer;
        margin-top: -15px;
      }

      .login-input-box-button {
        margin-top: 14px;
        cursor: pointer;
        width: 277.79px;
        height: 56.05px;
        line-height: 56.05px;
        text-align: center;
        background: url("../assets/image/bt_login.png") no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
  }
}
</style>
