/**
 * @Author: admin 幼发拉底娃娃鱼
 * @Date: 2020-09-08
 */
export const formValidate = {
  methods:{
    beforeFormSubmit(formName){
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate(v=>{
          if (v){
            resolve()
          }else{

          }
        })
      })
    }
  }
}

